import axios, { AxiosPromise } from 'axios';
import { AircraftCreationData, AircraftUpdateData } from '../../models/Aircraft';
import { apiUrl } from '../../utils/ApplicationUtils';

/**
 * Method to fetch companies from DB
 * @param apiToken JWT token to allow api call
 * @param params Query parameters (skip for pagination & sort)
 * @returns
 */
export function getAircrafts(apiToken: string | undefined, params?: any): AxiosPromise<any> {
    const url = apiUrl + '/aircraft';
    const options = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        },
        params: params
    };

    return axios.get(url, options);
}

export function createAircraft(apiToken: string | undefined, newAircraft: AircraftCreationData): AxiosPromise<any> {
    const url = apiUrl + '/aircraft';

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        }
    };

    return axios.post(url, newAircraft, headers);
}

export function deleteAircraft(apiToken: string | undefined, id: number): AxiosPromise<any> {
    const url = apiUrl + `/aircraft/${id}`;

    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        }
    };

    return axios.delete(url, headers);
}

export function patchAircraft(apiToken: string | undefined, id: number, data: AircraftUpdateData): AxiosPromise<any> {
    const url = apiUrl + `/aircraft/${id}`;
    const headers = {
        headers: {
            Authorization: 'Bearer ' + (apiToken ?? ''),
            'Content-Type': 'application/json'
        }
    };

    return axios.patch(url, data, headers);
}
