import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { AxiosPromise } from 'axios';
import i18n from 'i18next';
import { Button as AirbusButton, ColorModeProvider, Inline, Input, Tooltip, Typography } from '@airbus/components-react';
import { Drawer } from '@mui/material';
import { CheckCircle, Delete, Error } from '@airbus/icons/react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { AppContext } from '../../../AppContext';
import * as Utils from '../../../utils/ErrorUtils';
import { IApiError, IApiResponse } from '../../../models/AppModels';
import { Company, CompanyCreationData, CompanyUpdateData } from '../../../models/CompanyModel';
import {
    createCompany as createCompanyViaApiCall,
    deleteCompany as deleteCompanyViaApiCall,
    updateCompany as updateCompanyViaApiCall
} from '../../../services/api/CompaniesApi';
import { isTokenExpired } from '../../../utils/ApplicationUtils';
import { selectToken, selectTokenExpiryDate } from '../../../redux/app/features/credentialsSlice';

import './SideNav.css';
import CompanyTitle from './CompanyTitle';

type Props = {
    data?: Company;
    onDataUpdated: () => void;
    onClose: () => void;
    isUpdateMode: boolean;
};

const SideNavCompany = ({ data, onDataUpdated, onClose, isUpdateMode }: Props) => {
    const { setMessageBanner } = useContext(AppContext);

    const [open, setOpen] = useState(true);
    const [name, setName] = useState(data?.name);
    const [uuid] = useState(data?.uuid);
    const [technicalName, setTechnicalName] = useState(data?.technicalName);
    const [icao, setIcao] = useState(data?.icao);
    const [type] = useState(data?.type);

    const token = useSelector(selectToken);
    const tokenExpiryDate = useSelector(selectTokenExpiryDate);

    const doCompanyApiCall = (call: () => AxiosPromise<any>, crudAction: 'create' | 'update' | 'delete') => {
        !isTokenExpired(tokenExpiryDate ?? 0) &&
            call()
                .then((res: IApiResponse) => {
                    if (res.data.error) {
                        setMessageBanner({
                            isBanner: true,
                            message: res.data.error.message,
                            type: 'error'
                        });
                    } else {
                        setMessageBanner({
                            isBanner: true,
                            message: `Company was successfully ${crudAction}d`,
                            type: 'success'
                        });
                    }
                    onDataUpdated();
                })
                .catch((error: IApiError) => {
                    setMessageBanner({
                        isBanner: true,
                        message: Utils.displayErrorMessage(error),
                        type: 'error'
                    });
                });
    };

    const handleClose = () => {
        onClose();
        setOpen(false);
    };

    const createCompany = () => {
        if (technicalName && name) {
            const newCompany: CompanyCreationData = {
                name,
                technicalName,
                icao
            };
            doCompanyApiCall(() => {
                return createCompanyViaApiCall(token, newCompany);
            }, 'create');
        }
    };

    const updateCompany = () => {
        if (data) {
            const dataToUpdate: CompanyUpdateData = {
                name: name ?? data.name,
                icao: icao ?? data.icao,
                technicalName: technicalName ?? data.technicalName,
                uuid: uuid ?? data.uuid,
                type: type ?? data.type
            };
            doCompanyApiCall(() => {
                return updateCompanyViaApiCall(token, data.id, dataToUpdate);
            }, 'update');
        }
    };

    const deleteCompany = () => {
        if (data) {
            doCompanyApiCall(() => {
                return deleteCompanyViaApiCall(token, data.id);
            }, 'delete');
        }
    };

    const isCreateButtonEnabled = () => {
        return name && technicalName && validateTechName();
    };
    const isUpdateButtonEnabled = () => {
        return name && technicalName && validateTechName() && type === 'NORMAL';
    };
    const isDeleteButtonEnabled = () => {
        return type === 'NORMAL';
    };

    function validateTechName() {
        if (!technicalName || technicalName.match(/[^0-9a-z]/) || technicalName.length > 15) {
            return false;
        }
        return true;
    }

    return (
        <div>
            <Drawer
                anchor={'right'}
                open={open}
                onClose={handleClose}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: 1 / 4,
                        boxSizing: 'border-box'
                    }
                }}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>{<CompanyTitle handleClose={handleClose} />}</DialogTitle>
                <DialogContent>
                    <Typography className="input-label" variant="h6">
                        Name
                    </Typography>
                    <Input
                        disabled={type !== undefined && type !== 'NORMAL'}
                        onChange={(e: any) => {
                            setName(e.target.value);
                        }}
                        defaultValue={name}
                    />
                    <Typography className="input-label" variant="h6">
                        ICAO
                    </Typography>
                    <Input
                        disabled={type !== undefined && type !== 'NORMAL'}
                        onChange={(e: any) => {
                            setIcao(e.target.value);
                        }}
                        defaultValue={icao}
                    />
                    <div className="technical-name-input">
                        <Typography className="input-label" variant="h6">
                            Technical Name
                        </Typography>
                        {!isUpdateMode && (
                            <Tooltip
                                placement="bottom"
                                label={
                                    <>
                                        <p>Technical name must be,</p>
                                        <p> alphanumerical,</p>
                                        <p>without special caracters,</p>
                                        <p>lowercased,</p>
                                        <p>15 characters maximum</p>
                                    </>
                                }
                                className="custom-tooltip"
                                arrowClassName="custom-tooltip-arrow"
                                targetClassName="custom-tooltip-target">
                                <Error className={`custom-error ${!validateTechName() && 'error'}`} />
                            </Tooltip>
                        )}
                    </div>
                    <Input
                        onChange={(e: any) => {
                            setTechnicalName(e.target.value);
                        }}
                        disabled={isUpdateMode}
                        defaultValue={technicalName}
                    />
                </DialogContent>
                <ColorModeProvider mode="dark">
                    <div className="dialog-footer">
                        <DialogActions>
                            {isUpdateMode ? (
                                <Inline spacing="1-x">
                                    <AirbusButton
                                        disabled={!isDeleteButtonEnabled()}
                                        type="submit"
                                        icon={<Delete />}
                                        variant="error"
                                        onClick={() => {
                                            deleteCompany();
                                        }}>
                                        {i18n.t('admin.companies.dialog.delete-button')}
                                    </AirbusButton>
                                    <AirbusButton
                                        disabled={!isUpdateButtonEnabled()}
                                        type="submit"
                                        icon={<CheckCircle />}
                                        variant="success"
                                        onClick={() => {
                                            updateCompany();
                                        }}>
                                        {i18n.t('admin.companies.dialog.update-button')}
                                    </AirbusButton>
                                </Inline>
                            ) : (
                                <AirbusButton
                                    disabled={!isCreateButtonEnabled()}
                                    className="button"
                                    type="submit"
                                    icon={<CheckCircle />}
                                    variant="success"
                                    onClick={() => {
                                        createCompany();
                                    }}>
                                    {i18n.t('admin.companies.dialog.create-button')}
                                </AirbusButton>
                            )}
                        </DialogActions>
                    </div>
                </ColorModeProvider>
            </Drawer>
        </div>
    );
};

export default SideNavCompany;
