import { DataTable, SearchInput, Button } from '@airbus/components-react';
import i18n from 'i18next';
import React, { SyntheticEvent, useCallback, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Column, SortingRule, UseSortByColumnOptions } from 'react-table';

import { ApiCallbacks, displayAdminTextTranslation, useApiCall } from '../utils';
import { AppContext } from '../../../AppContext';
import { IApiError } from '../../../models/AppModels';
import { Aircraft, AircraftDataTable } from '../../../models/Aircraft';
import SideNavAircraft from '../side-nav/SideNavAircraft';
import MultipleChips from '../user/MultipleChips';
import * as Utils from '../../../utils/ErrorUtils';
import { aircraftToDataTable } from '../../../utils/models/aircraft.util';

import './../Table.css';
import classes from './AircraftTable.module.css';
import { snakeCaseToCamelCase } from '../../../utils/TypeUtils';

const columns: (Column<AircraftDataTable> & UseSortByColumnOptions<AircraftDataTable>)[] = [
    {
        Header: `${i18n.t('admin.aircrafts.table.headerCell1')}`,
        accessor: 'tailId'
    },
    {
        Header: `${i18n.t('admin.aircrafts.table.headerCell2')}`,
        accessor: 'airlineCompany',
        disableSortBy: true
    },
    {
        Header: `${i18n.t('admin.aircrafts.table.headerCell4')}`,
        accessor: 'intMsn'
    },
    {
        Header: `${i18n.t('admin.aircrafts.table.headerCell3')}`,
        Cell: ({ value }: { value: string }) => (
            <MultipleChips
                strings={value.split(',').filter((el) => {
                    return !!el;
                })}></MultipleChips>
        ),
        accessor: 'responsibleCompanies',
        disableSortBy: true
    }
];

type DataTableQuery<T extends Record<string, unknown> = {}> = {
    pageIndex: number;
    pageSize: number;
    sortBy: SortingRule<T>[];
};

const defaultSort = 'tailId';

export interface AirCraftTableProps {
    token: string | undefined;
    tokenExpiryDate: number | undefined;
}

export const AircraftTable: React.FC<AirCraftTableProps> = (props) => {
    // Error handling
    const { setMessageBanner } = useContext(AppContext);

    // DataTable
    /* DataTable props managed externally : data, loading, pageCount, rowCount */
    const [data, setData] = useState<Aircraft[]>([]);
    const [dataTable, setDataTable] = useState<AircraftDataTable[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const [rowCount, setRowCount] = useState(0);
    const [clickedData, setClickedData] = useState(-1);
    const { pathname } = useLocation();
    const [openSideNav, setOpenSideNav] = useState(false);
    const [sideNavDataUpdated, setSideNavDataUpdated] = useState<boolean>(true);

    const apiCallCallbacks: ApiCallbacks<any> = {
        onSuccess: (data: any, total: number) => {
            const camelCaseData = data.map((d: any) => snakeCaseToCamelCase<Aircraft>(d));
            const filteredAircrafts: AircraftDataTable[] = camelCaseData.map(aircraftToDataTable);
            setDataTable(filteredAircrafts);
            setData(camelCaseData);
            if (total !== rowCount) {
                setRowCount(total);
            }
            const newPageCount = Math.ceil(total / query.pageSize);
            if (newPageCount !== pageCount) {
                setPageCount(newPageCount);
            }
        },
        onError: (error: IApiError) => {
            setMessageBanner({
                isBanner: true,
                message: Utils.displayErrorMessage(error),
                type: 'error'
            });
        }
    };

    const [[query, setQuery], [setSearch], [loading]] = useApiCall(
        props.token,
        props.tokenExpiryDate,
        defaultSort,
        'aircraft',
        apiCallCallbacks,
        sideNavDataUpdated
    );

    const onQueryUpdate = useCallback((queryParams: DataTableQuery) => {
        if (queryParams.sortBy.length > 1) {
            queryParams.sortBy.splice(0, queryParams.sortBy.length - 1);
        }
        setQuery(queryParams);
    }, []);

    const onRowClick = (row: AircraftDataTable): void => {
        const id = data.findIndex((d) => d.id.toString() === row.id);
        if (id === -1) {
            setMessageBanner({
                isBanner: true,
                message: `Unexpected error`,
                type: 'error'
            });
            return;
        }
        setClickedData(id);
        setOpenSideNav(true);
        setSideNavDataUpdated(false);
    };

    const onChange = (event: SyntheticEvent<any, Event>, value: string): void => {
        setSearch(value);
        setQuery({ ...query, pageIndex: 0 });
    };

    const onDataUpdated = (): void => {
        setClickedData(-1);
        setOpenSideNav(false);
        setSideNavDataUpdated(true);
    };

    return (
        <div className={classes.page}>
            <div className={classes.page_container}>
                <div className={classes.page_sub_container}>
                    <div className={classes.temp}>
                        <SearchInput placeholder="Search" onChange={onChange} />
                    </div>
                    <Button
                        variant="primary"
                        className={classes.add_aircraft_button}
                        onClick={() => {
                            setSideNavDataUpdated(false);
                            setOpenSideNav(true);
                        }}>
                        {displayAdminTextTranslation(pathname, 'button')}
                    </Button>
                </div>
                <div className="generic-table">
                    <DataTable
                        columns={columns}
                        data={dataTable}
                        loading={loading}
                        onQueryUpdate={onQueryUpdate}
                        pageCount={pageCount}
                        rowCount={rowCount}
                        sortBy={query.sortBy}
                        onRowClick={onRowClick}
                    />
                </div>
                {openSideNav && (
                    <div>
                        <SideNavAircraft
                            data={clickedData !== -1 ? data[clickedData] : undefined}
                            isUpdateMode={clickedData !== -1}
                            onDataUpdated={onDataUpdated}
                            onClose={(): void => {
                                setOpenSideNav(false);
                                setClickedData(-1);
                            }}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
